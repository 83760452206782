import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import BottomMenu from "../components/bottom-menu.js";

import { useAuthContext } from '../hooks/useAuthContext';
// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

const WalletPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};

	const [withdrawAmount, setWithdrawAmount] = useState(100);
	const [balError, setBalError] = useState('');
	const [invalidAmount, setInvalidAmount] = useState(true);
	const handleAmountInput = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidAmount(true);
			setBalError(t('withdraw-error1'));
		} else if (e.target.value < 100) {
			setInvalidAmount(true);
			setBalError(t('withdraw-error2'));
		} else if (userData.withdrawable < e.target.value) {
			setInvalidAmount(true);
			setBalError(t('withdraw-error3'));
		} else {
			setInvalidAmount(false);
			setBalError('');
		}
		setWithdrawAmount(e.target.value);
	};

	const { submitWithdraw, setIsWithdrawing, isWithdrawing, withdrawSuccess, setWithdrawSuccess } = useWithdrawal();

	const userWithdraw = async () => {
		await submitWithdraw(userData._id, userData.wallet, withdrawAmount);
		setTimeout(() => fetchUserData(), 3000);
		setTimeout(() => fetchWithdrawData(), 4000);
		setTimeout(() => setWithdrawAmount(100), 4000);
	};

	const [withdrawData, setWithdrawData] = useState(null);
	const fetchWithdrawData = async () => {
		const response = await fetch('https://api.csg9.com/withdrawal/user/'+loggedInUser.user._id);
		const json = await response.json();
		if (response.ok) {
			console.log("Withdrawal Records:", json);
			setWithdrawData(json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
			fetchWithdrawData();
		}
	}, [loggedInUser]);

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						<div className="col-12 custom-page-padding">
							{userData ? (
								<div className="data-container">
									{/* <h3 className="">{t('wallet-title')}</h3>
									<hr></hr>
									<h5 className="mt-3">{t('wallet-item1')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.withdrawable).toFixed(2)}</span><strong>USDT</strong></p>
									</div>
									<p className="mt-2 mb-0">- {t('wallet-item2')}</p>
									<p className="mt-2 mb-0">- {t('wallet-item3')}</p>
									<p className="mt-2 mb-0">- {t('wallet-item4')}</p> */}
									{/* <button disabled className="btn action-btn py-2 mt-3">
										<p className="text-white mb-0"><strong>{t('withdraw-btn')}</strong></p>
									</button> */}
									<h3 className="">{t('wallet-title')}</h3>
									<hr></hr>
									<h5 className="mt-3">{t('wallet-item1')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.withdrawable).toFixed(2)}</span><strong>USDT</strong></p>
									</div>
									<p className="mt-2 mb-0">- {t('wallet-item2')}</p>
									<p className="mt-2 mb-0">- {t('wallet-item3')}</p>
									<p className="mt-2 mb-0">- {t('wallet-item4')}</p>
									
									{/* {isWithdrawing ? (
										<>
											<button disabled className="btn action-btn py-2 mt-2" data-dismiss="modal">
												<p className="text-white mb-0"><strong>{t('withdrawing-btn')}</strong></p>
											</button>
										</>	
									):(
										<>
											{!withdrawSuccess ? (
												<>
													<p className="mt-3 mb-2"><strong>{t('amount-input')}:</strong></p>
													<input
														type="number"
														placeholder='0'
														name='sellAmount'
														className='w-100'
														onChange={handleAmountInput}
													/>
													{balError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{balError}</p> : <></>}
													{!invalidAmount && withdrawAmount >= 100 ? (
														<>
															<p className="mt-2 mb-0" style={{fontSize:"14px"}}><strong>{t('withdraw-total')} ({t('withdraw-fee')}: {withdrawAmount < 250 ? "-5%" : "-2%"})</strong></p>
															{withdrawAmount < 250 ? (
																<p className="profit-green mb-0" style={{fontSize:"20px"}}><strong>{parseFloat(withdrawAmount-(withdrawAmount*0.05)).toFixed(2)} USDT</strong></p>
															):(
																<p className="profit-green mb-0" style={{fontSize:"20px"}}><strong>{parseFloat(withdrawAmount-(withdrawAmount*0.02)).toFixed(2)} USDT</strong></p>
															)}
														</>
													):(
														<></>
													)}
													<button disabled={userData.wallet == "" || userData.withdrawable < 100 || invalidAmount} className="btn action-btn py-2 mt-2" data-dismiss="modal" onClick={(e) => {
														setIsWithdrawing(true);
														userWithdraw();
													}}>
														<p className="text-white mb-0"><strong>{t('withdraw-btn')}</strong></p>
													</button>
												</>
											):(
												<>
													<p className="mt-3 mb-0 profit-green" style={{fontSize:"18px"}}><strong>{t('withdraw-success1')}</strong></p>
													<p className="mb-0" style={{fontSize:"18px"}}>{t('withdraw-success2')}</p>
													<button className="btn action-btn py-2 mt-3" data-dismiss="modal" onClick={(e) => {
														setWithdrawSuccess(false);
														setInvalidAmount(true);
													}}>
														<p className="text-white mb-0"><strong>{t('withdraw-complete')}</strong></p>
													</button>
												</>
											)}
										</>
									)} */}


									<h5 className="mt-5">{t('wallet-item5')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.split_bal).toFixed(2)}</span><strong>USDT</strong></p>
									</div>

									<h5 className="mt-5">{t('wallet-item6')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.insurance_bal).toFixed(2)}</span><strong>USDT</strong></p>
									</div>
									<p className="mt-2 mb-0"><span><img className="rank-icon mr-2" src={RedRank}></img></span>{t('insurance-red')}</p>
									<p className="mt-2 mb-0"><span><img className="rank-icon mr-2" src={SilverRank}></img></span>{t('insurance-silver')}</p>
									<p className="mt-2 mb-0"><span><img className="rank-icon mr-2" src={GoldRank}></img></span>{t('insurance-gold')}</p>
									<p className="mt-2 mb-0"><span><img className="rank-icon mr-2" src={PlatinumRank}></img></span>{t('insurance-platinum')}</p>

								</div>
							):(
								<></>
							)}

							<div className="data-container mt-5">
								<h3 className="">{t('wallet-title2')}</h3>
								<hr></hr>
								{withdrawData && withdrawData.length > 0 ? (
									<>
										{withdrawData && withdrawData.map((withdraw, index) => (
											<div key={index}>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>{t('withdraw-date')}: </strong>{Moment(withdraw.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
												<p className="mb-0" style={{fontSize:"14px"}}><strong>{t('withdraw-amount')}: </strong>{parseFloat(withdraw.usdt_amount).toFixed(2)} USDT</p>
												{withdraw.status == "Processing" ? <p className="brand-yellow mb-0" style={{fontSize:"14px"}}><strong>{t('withdraw-processing')}</strong></p> : <></>}
												{withdraw.status == "Completed" ? <p className="profit-green mb-0" style={{fontSize:"14px"}}><strong>{t('withdraw-completed')}</strong></p> : <></>}
												{withdraw.txn_link != "" ? <p className="mb-0" style={{fontSize:"14px"}}><a href={withdraw.txn_link} target="_blank">{t('withdraw-txn')}</a></p> : <></>}
												<hr></hr>
											</div>
										))}
									</>
								):(
									<p className="mb-0">{t('wallet-no-record')}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>

			<BottomMenu></BottomMenu>
		</>
	);

};

export default WalletPage;