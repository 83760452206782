import React, { useEffect, useState } from 'react';

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import Notice1 from '../assets/img/notice-1.png';
import Notice2 from '../assets/img/notice-2.png';

import BottomMenu from "../components/bottom-menu.js";

import { useAuthContext } from '../hooks/useAuthContext.js';

const NewsPage = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						<div className="col-12 custom-page-padding">
							<h3 className="brand-primary text-center">{t('news-main-title')}</h3>
							<h5 className="text-center mb-0">{t('news-update-time')}</h5>
							<img className="w-100 mt-5" src={Notice2}></img>
							<img className="w-100 mt-5" src={Notice1}></img>
						</div>
					</div>
				</div>
			</section>
		</>
	);

};

export default NewsPage;